import React, { Component } from 'react'
import '../css/introduction.css'
import cv from '../media/Alaa Badreddine - CV English.pdf';
import cv2 from '../media/Alaa Badreddine - CV Français.pdf';
import { saveAs } from 'file-saver';

export default class Introduction extends Component {
  isMobile = () => window.innerWidth <= 768;

  handleDownload = (file, fileName) => {
    if (this.isMobile()) {
      window.open(file, '_blank');
    } else {
      saveAs(file, fileName);
    }
  }

  render() {
    // Decide on the text based on the screen width
    const downloadText = this.isMobile() ? "Download CV - EN" : "Download CV in English";
    const downloadText2 = this.isMobile() ? "Download CV - FR" : "Download CV in French";
    return (
      <div>
        <section id="colorlib-hero" className="js-fullheight" data-section="home">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li className="webp-bg" style={{ backgroundImage: 'url(images/b.webp)' }}>
                <div className="overlay"></div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <h1>Hi! I'm <a className="glow-on-hover" href="https://www.google.com/search?q=alaa+name+meaning" target="_blank" rel="noopener noreferrer">Alaa!</a></h1>
                          <p><a className="btn btn-primary btn-learn" href={cv} download="AlaaBadreddine_CV_EN.pdf">
                            {downloadText} <i className="icon-download4"></i></a></p>
                          <p><a className="btn btn-primary btn-learn" href={cv2} download="AlaaBadreddine_CV_FR.pdf">
                            {downloadText2} <i className="icon-download4"></i></a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="webp-bg" style={{ backgroundImage: 'url(images/a.webp)' }}>
                <div className="overlay"></div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1>I'm a <br></br><a className="glow-on-hover" href="https://www.google.com/search?q=bioinformatician" target="_blank" rel="noopener noreferrer">Bioinformatician</a></h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section >
      </div >
    )
  }
}
