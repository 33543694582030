import React, { Component } from 'react'

export default class Introduction extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-education" data-section="education">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <h2 className="colorlib-heading animate-box">Education</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                <div className="fancy-collapse-panel">
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title">
                          <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Machine Learning Certificate - Udemy — 2023
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-12">
                              <p>In 2023, I expanded my expertise with a Machine Learning Certificate from Udemy, diving into the world of algorithms, data science, and artificial intelligence. This intensive program equipped me with practical skills in designing, implementing, and deploying machine learning models. It was a hands-on experience that allowed me to apply theoretical concepts to real-world datasets, improving my proficiency in predictive analytics and deepening my understanding of neural networks and AI applications.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingThree">
                        <h4 className="panel-title">
                          <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Master Degree in Bioinformatics - Aix-Marseille University, Marseille, France — 2015 - 2017
                          </a>
                        </h4>
                      </div>
                      <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6">
                              <p>During my Master's degree in Bioinformatics, Structural Biochemistry, and Genomics (BBSG) at Aix-Marseille University, I immersed myself in the intricate world of biological data. I honed my skills in high-throughput techniques, such as transcriptomics and proteomics, gaining a deep understanding of the bioinformatics landscape. My focus was particularly drawn to data analysis, where I learned to interpret the vast genomic datasets unveiled by cutting-edge sequencing technologies.</p>
                            </div>
                            <div className="col-md-6">
                              <p>I embraced the challenges of functional genomics, applying post-genomic data to elucidate gene expression patterns and biological interactions on a large scale. My journey through the BBSG program equipped me with the knowledge to contribute to cellular component analyses across diverse organisms. Bioinformatics was more than sequence comparison for me—it became the lens through which I viewed complex biological questions, leading to insights in various fields from developmental biology to immunology.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Bachelor Degree in Biology - Lebanese University, Beirut, Lebanon — 2009 - 2015
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6">
                              <p>My journey continued with a Bachelor's Degree in Biology at the Lebanese University in Beirut. This comprehensive program provided me with a strong grounding in biological principles, from the microscopic intricacies of cell biology to the expansive concepts of ecology and evolution. I engaged with hands-on laboratory work and rigorous research, developing critical thinking and analytical skills.</p>
                            </div>
                            <div className="col-md-6">
                              <p>The curriculum not only covered a broad spectrum of theoretical knowledge but also emphasized practical experience, which has been instrumental in shaping my scientific perspective. My undergraduate studies laid the cornerstone for my subsequent specialization, allowing me to build a robust platform for advanced studies in the dynamic and ever-evolving field of biology.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingFive">
                        <h4 className="panel-title">
                          <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Baccalaureate in Life Sciences - Check Anta Diop, Bamako, Mali — 2009
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-6">
                              <p>My academic pursuits in the realm of life sciences took root at Cheick Anta Diop High School in Bamako, Mali, where I completed my Baccalaureate. It was here that my fascination with the intricate mechanisms of life began to flourish. The curriculum was rigorous and expansive, covering a diverse range of subjects that provided a holistic understanding of the biological world.</p>
                            </div>
                            <div className="col-md-6">
                              <p>This critical stage of my education was marked by a blend of theoretical learning and practical inquiry, setting the stage for my future studies. The comprehensive approach of the program instilled in me a deep respect for the empirical nature of science and a drive to contribute to its advancement through further education and research.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}