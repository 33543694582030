import React, { Component } from 'react'

export default class Introduction extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: ''
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    console.log('Form submitted with these fields:', this.state);


    const apiUrl = ''; // to do, thinking of doing it with google cloud functions 


    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state)
    };


    fetch(apiUrl, fetchOptions)
      .then(response => {
        if (response.ok) {
          return response.json(); // Process the response if it's OK
        }
        throw new Error('Network response was not ok.');
      })
      .then(data => {
        console.log('Success:', data);
        this.setState({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
        // to do: handle the error
      });
  }

  render() {
    return (
      <div>
        <section className="colorlib-contact" data-section="contact">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">Get in Touch</span>
                <h2 className="colorlib-heading">Contact</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 col-md-push-1">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Name"></input>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Email"></input>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Subject"></input>
                      </div>
                      <div className="form-group">
                        <textarea name="" id="message" cols="60" rows="7" className="form-control" placeholder="Message"></textarea>
                      </div>
                      <div className="form-group">
                        <input type="submit" className="btn btn-primary btn-send-message" value="Send Message"></input>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}