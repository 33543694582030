import React, { Component } from 'react'

export default class Introduction extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="experience">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <h2 className="colorlib-heading animate-box">Work Experience</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">

                      <div className="timeline-icon color-1">
                        <i className="icon-pen2"></i>
                      </div>

                      <div className="timeline-label">
                        <h2><a href="#">Systems Specialist in Bioinformatics</a> <span>EPFL, Lausanne, Switzerland — 2023 - Present</span></h2>
                        <p>At EPFL, within the innovative environment of Johan Auwerx's laboratory, I took the role of a Systems Specialist in Bioinformatics. My multifaceted responsibilities include crafting intuitive web interfaces, conducting detailed analyses of mice data, and ensuring robust IT administration. Additionally, I provide vital IT support for the team and troubleshooting any technical issues that arise.</p>
                      </div>
                    </div>
                  </article>

                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2"></i>
                      </div>
                      <div className="timeline-label">
                        <h2><a href="#">Bioinformatics Engineer / IT administrator</a> <span>EGID, Lille, France — 2018 - 2023</span></h2>
                        <p>In the renowned laboratory of Pr. Philippe Froguel at EGID, Lille, I pushed further my knowledge as a Bioinformatics Engineer and IT Administrator, honing my expertise in the extensive analysis of DNA and RNA data. A triple responsibility marked my tenure: investigating into genetic data to uncover valuable insights, building web interfaces to ease the team's work and managing the server infrastructure to ensure seamless research operations. My efforts significantly accelerated the analysis timeline for samples, boosting the productivity of the researchers in the laboratory.</p>
                      </div>
                    </div>
                  </article>

                  <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2"></i>
                      </div>
                      <div className="timeline-label">
                        <h2><a href="#">Bioinformatics Engineer</a> <span>IGBMC, Strasbourg, France — 2017 - 2018</span></h2>
                        <p>Following my internship, Pr. Hinrich Gronemeyer recognized the value of my contributions and brought me on board as a Bioinformatics Engineer. My persistent work on RNA-seq and DNA-seq data analysis was pivotal in our research, leading to a publication in which I had the honor of being cited as a co-author. This role not only solidified my expertise in the field but also highlighted the impact of my analytical abilities on advancing our understanding of genetic information.</p>
                      </div>
                    </div>
                  </article>

                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-pen2"></i>
                      </div>
                      <div className="timeline-label">
                        <h2><a href="#">Bioinformatics Internship</a> <span>IGBMC, Strasbourg, France — 2017</span></h2>
                        <p>As part of my Master's degree, I undertook a bioinformatics internship at IGBMC in Strasbourg under the supervision of Pr. Hinrich Gronemeyer, where I focused on analyzing RNA-seq and DNA-seq data from human samples. This hands-on experience not only enhanced my computational biology skills but also provided me with invaluable insights into the complexities of genetic sequencing and expression profiling, critical for advancing personalized medicine.</p>
                      </div>
                    </div>
                  </article>

                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}